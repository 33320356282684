export const APP_NAME: string = 'ChargeTrack 1.1';

export const BASE_URL: string = process.env.REACT_APP_BASE_API_URL!;

export const X_API_KEY: string = process.env.REACT_APP_X_API_KEY!;

export function currencyFormat(value: number, decimalPlaces: number, currencySymbol?: boolean): string {
  let options: any = {
    minimumFractionDigits: decimalPlaces
  }
  if (currencySymbol) {
    options['style'] = 'currency';
    options['currency'] = 'USD';
  }
  const currency = new Intl.NumberFormat('en-US', options);
  return currency.format(value);
}

export function sortOptions(items: Array<any>, key: string, orderState?: number, caseCompare?: boolean) {
  return items.sort((currentView: any, nextView: any) => {
    const current = caseCompare ? currentView[key].toLowerCase() : currentView[key];
    const next = caseCompare ? nextView[key].toLowerCase() : nextView[key];
    if (current > next) {
      return orderState ? -1 : 1
    } else {
      return orderState ? 1 : -1
    }
  })
}

export function transformItemToDropdownOption(options: Array<any>) {
  return options.map((option) => ({
    key: option.id,
    value: option.id,
    text: option.text
  }))
}
