import TableComponent from "components/TableComponent";
import { Column, IPriceList } from "interface";
import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Table } from 'semantic-ui-react';
import { requestPriceList } from "store/list";
import { getPriceListState } from "store/selectors";
import { sortOptions } from "utils/global";
import PriceListRow from "./PriceListRow";

interface PriceListColumn extends Column {
  isAdmin: boolean;
}

const columns: PriceListColumn[] = [
  { name: '', value: 'edit', sort: false, isAdmin: true },
  { name: 'ID', value: 'id', sort: true, isAdmin: false },
  { name: 'Product/Service', value: 'product', sort: true, isAdmin: false },
  { name: 'Description', value: 'description', sort: true, isAdmin: false },
  { name: 'Price', value: 'price', sort: true, isAdmin: false },
  { name: 'Default Discount', value: 'default_discount', sort: true, isAdmin: false },
  { name: 'Accounting Vendor Code', value: 'acc_vendor_code', sort: true, isAdmin: true },
  { name: 'Valid for New Entries', value: 'valid_for_new_entries', sort: true, isAdmin: true },
  { name: 'Rev Code 1', value: 'rev_code_1', sort: true, isAdmin: true },
  { name: 'Rev Code 2', value: 'rev_code_2', sort: true, isAdmin: true },
  { name: 'Rev Code 1 %', value: 'rev_code_1_percent', sort: true, isAdmin: true }
];

const PriceList = () => {

  const { practice_id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: priceList, fetching: priceListFetching } = useSelector(getPriceListState);
  const dispatch = useDispatch();

  const sortPriceList: IPriceList[] = useMemo(() => {
    const column = searchParams.get('col')!;
    const direction = searchParams.get('dir');
    if (column && direction) {
      return sortOptions(priceList, column, direction === "descending" ? 1 : 0)
    }
    return priceList
  }, [priceList, searchParams]);

  const [isAdminPath, colsToRender] = useMemo(() => {
    const isPriceListAdmin = (location.pathname).split('/').at(-1) === 'admin-price-list';
    const columnsToRender = isPriceListAdmin ? columns : columns.filter(col => !col.isAdmin);
    return [isPriceListAdmin, columnsToRender];
  }, [location, columns]);

  useEffect(() => {
    if (practice_id) {
      dispatch(requestPriceList(practice_id, isAdminPath));
    }
  }, [practice_id])

  const renderPriceListRow = (item: IPriceList) => {
    const { id } = item;
    return (
       <Table.Row key={id}>
        <PriceListRow item={item} isAdminPath={isAdminPath} />
      </Table.Row>
    )
  }

  const addPriceListItemBtn = () => {
    return (
      <Button secondary size="tiny" onClick={() => navigate('add')} className="text-align-right margin-b-8">
        Add Price List Item
      </Button>
    )
  }

  return (
    <Fragment>

      <div className={isAdminPath ? 'width-90' : 'width-60'}>
        {
          isAdminPath && addPriceListItemBtn()
        }
        <h5 className="margin-b-9">Current PriceList items :</h5>
        <TableComponent noDataMessage="There are currently no PriceList items."
          fetching={!!priceListFetching} columns={colsToRender} rows={sortPriceList} >
          {
            sortPriceList?.map(renderPriceListRow)
          }
        </TableComponent>
      </div>

    </Fragment>
  );
};

export default PriceList;