import { Column, IChargeBack, IPractice } from "interface";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Table } from 'semantic-ui-react';
import { getAppState, getSearchtems } from "store/selectors";
import TableComponent from "components/TableComponent";
import { toast } from "react-toastify";
import SearchResultRows from "./SearchResultRows";
import { currencyFormat, sortOptions } from "utils/global";
import { requestSearch } from "store/rext";
import SearchResultsReviewButtons from "./SearchResultsReviewButtons";
import InfiniteScroll from 'react-infinite-scroller';

const columns: Column[] = [
  { name: '', value: 'edit', sort: false },
  { name: '', value: 'delete', sort: false },
  { name: 'ID', value: 'id', sort: true },
  { name: 'Rev', value: 'rev', sort: true },
  { name: 'Post', value: 'post', sort: true },
  { name: 'Post Date', value: 'post_date', sort: true },
  { name: 'Staff', value: 'staff', sort: true },
  { name: 'Date', value: 'date', sort: true },
  { name: 'Project#', value: 'project_no', sort: true },
  { name: 'Rev Code', value: 'rev_code', sort: true },
  { name: 'Office', value: 'office', sort: true },
  { name: 'Requestor', value: 'requestor', sort: true },
  { name: 'Time', value: 'time', sort: true },
  { name: 'Product/Service', value: 'product', sort: true },
  { name: 'Qty', value: 'qty', sort: true },
  { name: 'Total List Price', value: 'total_list_price', sort: true },
  { name: 'Discount%', value: 'discount', sort: true },
  { name: 'Total Discount Price', value: 'total_discount_price', sort: true },
  { name: 'Discount Reason', value: 'discount_reason', sort: true },
  { name: 'Comments', value: 'comments', sort: true }
];

const limit = 100;

const SearchResults = () => {

  const { practice_id } = useParams();
  const { data: appData } = useSelector(getAppState);
  const [searchParam] = useSearchParams();
  const dispatch = useDispatch();
  const [checkboxes, setCheckboxes] = useState<any>({})
  const isAdmin: boolean = useMemo(() => {
    const practiceArea: IPractice = appData?.list?.find((practice: IPractice) => practice_id && practice.id === parseInt(practice_id))
    return practiceArea?.role === "ADMIN";
  }, [practice_id, appData]);
  const { data: searchData, fetching: searchListfetching } = useSelector(getSearchtems);
  const { list: fullSearchList = [], quantity, listPrice, totalDiscountPrice } = searchData;
  const [paging, setPaging] = useState({ start: 0, end: limit });
  const [hasMore, setHasMore] = useState<boolean>(true);

  const toggleCheckbox = (id: number) => (event: any, { checked }: any) => {
    setCheckboxes((prevsState: any) => ({
      ...prevsState,
      [id]: checked
    }))
  }

  const deleteResult = (item: IChargeBack, index: number) => {
    const { quantity: itemQty, listPrice: itemListPrice, totalDiscountedPrice } = item;
    fullSearchList.splice(index, 1);
    searchData.quantity = searchData.quantity - itemQty;
    searchData.listPrice = searchData.listPrice - itemListPrice;
    searchData.totalDiscountPrice = searchData.totalDiscountPrice - totalDiscountedPrice;
    toast.info("Charge Back Deleted");
  }

  const renderSearchResultRows = (item: IChargeBack, index: number) => {
    const { id } = item;
    return (
      <Table.Row key={id}>
        <SearchResultRows practice_id={practice_id} item={item} isAdmin={isAdmin} checkboxes={checkboxes}
          deleteResult={() => deleteResult(item, index)} toggleCheckbox={() => toggleCheckbox(id)} />
      </Table.Row>
    )
  }

  const cancelChanges = () => {
    setCheckboxes({});
  }

  const searchPageButtons = (position: string) => {
    return (
      <SearchResultsReviewButtons practice_id={practice_id} isAdmin={isAdmin} checkboxes={checkboxes} searchList={fullSearchList} position={position}
        searchListfetching={searchListfetching} sortSearchList={sortSearchList} searchParam={searchParam} cancelChanges={cancelChanges} />
    )
  }

  const searchTotals = () => {
    return (
      <Table.Row>
        <Table.Cell colSpan={13}></Table.Cell>
        <Table.Cell><b>Totals</b></Table.Cell>
        <Table.Cell><b>{quantity}</b></Table.Cell>
        <Table.Cell><b>{currencyFormat(listPrice, 2, true)}</b></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell><b>{currencyFormat(totalDiscountPrice, 2, true)}</b></Table.Cell>
        <Table.Cell colSpan={2}></Table.Cell>
      </Table.Row>
    )
  }

  const sortSearchList: IChargeBack[] = useMemo(() => {
    const column = searchParam.get('col');
    const direction = searchParam.get('dir');
    if (column && direction) {
      const sortedList = sortOptions(fullSearchList, column, direction === "descending" ? 1 : 0, ['requestor', 'staff'].includes(column));
      return sortedList.slice(0, limit);
    }
    return fullSearchList.slice(0, limit)
  }, [fullSearchList, searchParam]);

  useEffect(() => {
    if (practice_id) {
      dispatch(requestSearch(practice_id, searchParam));
    }
  }, [practice_id])

  const loadMoreRows = () => {
    if (searchListfetching || !fullSearchList.length) return;
    if (fullSearchList.length > sortSearchList.length) {
      sortSearchList.push(...(fullSearchList).slice(paging.end, paging.end + limit))
      setPaging({ start: paging.end, end: paging.end + limit });
      return;
    }
    setHasMore(false);
  }

  return (
    <Fragment>
      <div className="width-100">

        <h5 className="margin-b-9">Search results :</h5>

        {
          searchPageButtons('top')
        }

        <InfiniteScroll
          loadMore={loadMoreRows}
          hasMore={hasMore}>
          <TableComponent noDataMessage="There are currently no ChargeBacks that match those criteria."
            fetching={!!searchListfetching} columns={columns} rows={sortSearchList} >
            {
              sortSearchList?.map(renderSearchResultRows)
            }
            {
              searchTotals()
            }
          </TableComponent>
        </InfiniteScroll>

        {
          searchPageButtons('bottom')
        }

      </div>
    </Fragment>
  );
};

export default SearchResults;