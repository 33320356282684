import { Fragment, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { FormDatePicker, FormDropdown, FormInput } from "components/FormInputs";
import { requstSearchDropdown } from "store/rext";
import { getAllSearchDropdown, getAppState } from "store/selectors";
import { useSearchParams } from "react-router-dom";

interface ISearch {
  services: any[],
  from: Date | string,
  to: Date | string,
  postDate: Date | string,
  userId: string,
  officeCode: string,
  review: string,
  post: string,
  project: string,
  requestor: string
}

function Search() {
  const { practice_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: dropdownState } = useSelector(getAllSearchDropdown)
  const { data: appData } = useSelector(getAppState);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(requstSearchDropdown(practice_id));
  }, [dispatch])

  const { register, handleSubmit, setValue, reset, getValues, formState: { errors }, resetField, trigger } = useForm<ISearch>();

  const createSearchParams = (data: any) => {
    let params: any = [];
    Object.keys(data).forEach(key => {
      if ((Array.isArray(data[key]) && data[key].length) || (!Array.isArray(data[key]) && data[key])) {
        if (['from', 'to', 'postDate'].includes(key)) {
          params.push(`${key}=${moment(data[key]).valueOf()}`);
        } else {
          params.push(`${key}=${data[key]}`);
        }
      }
    });
    return params.join('&');
  }

  const onSubmit = (data: ISearch) => {
    const params = createSearchParams(data);
    setSearchParams(params);
    navigate({
      pathname: 'results',
      search: `?${params}`,
    });
  }

  const handleClear = () => {
    reset({
      services: [],
      from: "",
      to: "",
      postDate: "",
      userId: "ALL",
      officeCode: "ALL",
      review: "ALL",
      post: "ALL",
      project: "",
      requestor: ""
    })
  }

  useEffect(() => {
    reset({
      services: searchParams.get('services')?.split(',') || [],
      from: searchParams.get('from') ? new Date(parseInt(searchParams.get('from')!)) : new Date(new Date().setMonth(new Date().getMonth() - 1)),
      to: searchParams.get('to') ? new Date(parseInt(searchParams.get('to')!)) : new Date(),
      postDate: searchParams.get('postDate') ? new Date(parseInt(searchParams.get('postDate')!)) : new Date(),
      userId: searchParams.get('userId') || appData?.user?.id.toString(),
      officeCode: searchParams.get('officeCode') || "ALL",
      review: searchParams.get('review') || "ALL",
      post: searchParams.get('post') || "ALL",
      project: searchParams.get('project') || "",
      requestor: searchParams.get('requestor') || ""
    });
  }, [searchParams])

  return (

    <Fragment>
      <div className="width-30">
        <div className="flex flex-row margin-t-8 margin-b-9">
          <h5 className="text-capitalize margin-r-3 margin-b-0 flex-align-self-center">search for an existing charge back: </h5>
          <Link to="results" className="text-bold text-hover-underline text-grey-color text-hover-primary">
            (or click here to view all)
          </Link>
        </div>

        <Form size="small" onSubmit={handleSubmit(onSubmit)}>
          <FormDatePicker
            label='From'
            name='from'
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          <FormDatePicker
            label='To'
            name='to'
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          <FormDropdown
            label='Staff'
            placeholder="All"
            name='userId'
            resetLabel="All Staff"
            handleResetValue={() => {
              resetField("userId", {
                defaultValue: "ALL"
              })
            }}
            register={register}
            options={dropdownState.users || []}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          <FormInput
            label='Project #'
            name='project'
            register={register}
          />

          <FormDropdown
            label='Office'
            name='officeCode'
            options={dropdownState.locations || []}
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
          />

          <FormInput
            label='Requestor'
            name='requestor'
            register={register}
          />

          <FormDropdown
            label='Product or Service (Select Multiple)'
            name='services'
            options={dropdownState.services || []}
            multipleSelection={true}
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          <FormDropdown
            label='Reviewed'
            name='review'
            options={dropdownState.reviewDropdown || []}
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          <FormDropdown
            label='Posted'
            name='post'
            options={dropdownState.postDropdown || []}
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          <FormDatePicker
            label='Post Date'
            name='postDate'
            register={register}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          <div className="margin-t-8 flex flex-justify-end">
            <div className="margin-r-2">
              <Button type="button" size='tiny' onClick={handleClear}>Clear Form</Button>
            </div>
            <Button type='submit' size='tiny' primary>Search</Button>
          </div>
        </Form>
      </div>
    </Fragment>

  );
}

export default Search;